import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BGImg from "../../../assets/images/bread_crumb_bg.png";
import SiteService from "../../../vendors/service/siteService";
const Main = ({ brdcum, bgimg }) => {
  const [blogData, setBlogData] = useState([]);
  console.log(blogData);

  const api = new SiteService();
  useEffect(() => {
    if(blogData.length===0){getBlogData()};
  }, [blogData]);

  const getBlogData = async () => {
    try {
      const res = await api.blogData();

      setBlogData(res.data);

      // console.log(res.data);

      
    } catch (error) {
      console.log("Error from blog data ", error);
    }
  };
  
  return (
    <>
      <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
        <div className="container">
          <span className="banner_shape1">
            {" "}
            <img src="assets/images/banner-shape1.png" alt="image" />{" "}
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="assets/images/banner-shape2.png" alt="image" />{" "}
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="assets/images/banner-shape3.png" alt="image" />{" "}
          </span>

          <div className="bred_text">
            <h1>Latest Blog Post</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>»</span>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
            {/* <div className="search_bar">
                    <form action="">
                        <div className="form-group">
                            <input type="text" placeholder="Search here" className="form-control"/>
                            <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                        </div>
                    </form>
        </div>*/}
          </div>
        </div>
      </div>

      <section className="row_am blog_list_main">
        {blogData.map((data,index) => {
          if (index === 0){
            return (
              <div className="container">
              <div className="row">
                <div
                  className="col-lg-6"
                  // data-aos="fade-in"
                  // data-aos-duration="1500"
                >
                  <div className="blog_img">
                    <img 
                      src={data.meta_value}
                      alt="image"
                    />
                    {/* <span>20 min ago</span> */}
    
                    <span>{new Date (data.post_date).toDateString()}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blog_text">
                    <div className="section_title">
                      <h2>{data.post_title}</h2>
                      <div dangerouslySetInnerHTML={{__html: data.post_content.substring(0,400)+'  . . .'}}></div>
                      {/* <Link to={`/blog-single/${data.id}`} state={{data}}>
                          READ MORE
                        </Link> */}
                        <Link to={`/blogdetails/${data.id}`} state={{data, allData: blogData}}>
                      READ MORE
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            )
          }          
        })}
       
       
      </section>
      <section className="row_am latest_story blog_list_story" id="blog">
        <div className="container">
          <div className="row">
            {blogData.map((data, index) => {
              if (index > 0){
                return (
                  <div key={data.id} className="col-md-4">
                <div className="story_box">
                  <div className="story_img">
                    <img src={data.meta_value} alt="image" />
                    <span>{new Date (data && data.post_date).toDateString()}</span>
                  </div>
                  <div className="story_text">
                    <h3>{data.post_title}</h3>
                    <div dangerouslySetInnerHTML={{__html: data && data.post_content.substring(0,100)+'  . . .'}}></div>
                    <Link to={`/blogdetails/${data.id}`} state={{data, allData: blogData}}>
                      READ MORE
                    </Link>
                  </div>
                </div>
              </div>

                )}

              
              })}
          </div>
          <div className="pagination_block">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BLOGSITE}
                 className="prev">
                  {" "}
                  Visit our blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../component/Navbar/Main";
import Footer from "../component/Footer/Main";
import Home from "../component/Home/Main";
import AboutUs from "../component/AboutUs/Main";
import Review from "../component/Pages/Review/Main";
import Contact from "../component/Contact/Main";
import Faq from "../component/Pages/Faq/Main";
import SignIn from "../component/Pages/SignIn/Main";
import SignUp from "../component/Pages/SignUp/Main";
import Pricing from "../component/Pricing/Main";
import Blog from "../component/Blog/Blog/Main";
import BlogSingle from "../component/Blog/BlogSingle/Main";


import Terms from "../component/Terms/Main";
import Privacy from "../component/Privacy/Main";

/**
 * Renders the appropriate component based on the current URL path.
 * Manages the state of the navbar and footer components based on the current path.
 * @returns {JSX.Element} The rendered components based on the current URL path.
 */
const Routing = () => {
  const [homepage, setHomepage] = useState(false);
  const [footerpage, setFooterpage] = useState(false);
  const [footer, setFooter] = useState({ f1: true });
  const [navbar, setNavbar] = useState({ n1: true });
  const [brdcum, setBrdcum] = useState({ n1: true });
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
      setHomepage(false);
    } else {
      setHomepage(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
      setFooterpage(false);
    } else {
      setFooterpage(true);
    }
  }, [location]);

  useEffect(() => {
    const navbarValue = localStorage.getItem("navbar");
    if (navbarValue === "darkhome") {
      setFooter({ f2: true });
      setNavbar({ n3: true });
      setBrdcum({ b2: true });
    } else if (navbarValue === "home") {
      setFooter({ f1: true });
      setNavbar({ n2: true });
      setBrdcum({ b1: true });
    } else {
      setFooter({ f1: true });
      setNavbar({ n2: true });
    }
  }, []);

  return (
    <>
      {homepage && <Navbar navbar={navbar} />}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setfooter={setFooter}
              setnavbar={setNavbar}
              setbrdcum={setBrdcum}
            />
          }
        />
        <Route
          path="/about"
          element={<AboutUs setnavbar={setNavbar} brdcum={brdcum} />}
        />
        <Route
          path="/review"
          element={<Review setnavbar={setNavbar} brdcum={brdcum} />}
        />
        <Route path="/contact" element={<Contact brdcum={brdcum} />} />
        <Route path="/faq" element={<Faq brdcum={brdcum} />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/pricing" element={<Pricing brdcum={brdcum} />} />
        <Route path="/blog" element={<Blog brdcum={brdcum} />} />
        <Route path="/blogdetails/:id" element={<BlogSingle brdcum={brdcum} />} />
        <Route path="/terms" element={<Terms brdcum={brdcum} />} />
        <Route path="/privacy" element={<Privacy brdcum={brdcum} />} />
      </Routes>
      {footerpage && <Footer footer={footer} />}
    </>
  );
};

export default Routing;

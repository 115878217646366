import { HashLink as Link } from "react-router-hash-link";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import OwlCarousel from "react-owl-carousel";
import Features from "../HomeMain/Features/Main";
import Work from "../HomeMain/Work/Main";
import Faq from "../HomeMain/Faq/Main";
import Interface from "../HomeMain/Interface/Main";
import Download from "../HomeMain/Download/Main";
import Story from "../HomeMain/Story/Main";
import anim from "../../assets/images/anim_line.png";
import blueapp from "../../assets/images/appstore_blue.png";
import whiteapp from "../../assets/images/appstore_white.png";
import blue from "../../assets/images/googleplay_blue.png";
import white from "../../assets/images/googleplay_white.png";
import msg from "../../assets/images/message_icon.png";
import shield from "../../assets/images/shield_icon.png";
import screen from "../../assets/images/screen.png";
import firstSlide from "../../assets/images/myfitscreenhome.png";
import secondSlide from "../../assets/images/myfitscreenlogin.png";
import thirdSlide from "../../assets/images/myfitscreenstartpage.png";
import frame from "../../assets/images/mobile_frame_svg.svg";

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true });
    setnavbar({ n2: true });
    setbrdcum({ b1: true });
    localStorage.setItem("navbar", "home");
  }, []);

  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section className="banner_section home-banner">
        <div className="container">
          <div className="anim_line">
            {[...Array(9)].map((_, index) => (
              <span key={index}>
                <img src={anim} alt="anim_line" />
              </span>
            ))}
          </div>
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner_text">
                <h1>
                  your Personal<span> health care platform.</span>
                </h1>
                <p>
                  myFit is your personal health care concierge. this app helps
                  you to manage the intricacies of your daily and long term
                  health activities. With myFit, your health record is yours at
                  all point, you decide your provider access as needed from time
                  to time.
                </p>
              </div>
              <ul className="app_btn">
                <li>
                  <a href={process.env.REACT_APP_ANDROID_URL}>
                    <img src={blueapp} alt="image" />
                    <img className="white_img" src={whiteapp} alt="image" />
                  </a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_IOS_URL}>
                    <img src={blue} alt="image" />
                    <img className="white_img" src={white} alt="image" />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="banner_slider">
                <div className="left_icon">
                  <img src={msg} alt="image" />
                </div>
                <div className="right_icon">
                  <img src={shield} alt="image" />
                </div>
                <OwlCarousel
                  id="frmae_slider"
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                  {...frmae_slider}
                >
                  <div className="item">
                    <div className="slider_img">
                      <img src={firstSlide} alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={secondSlide} alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={thirdSlide} alt="image" />
                    </div>
                  </div>
                </OwlCarousel>
                <div className="slider_frame">
                  <img src={frame} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Features />
      <Work />
      <Faq />
      <Interface />
      <Download />
      <Story />
    </>
  );
};

export default Main;

import React ,{ useState, useEffect } from 'react'
import SiteService from '../../../vendors/service/siteService'

const Main = ({gredient}) => {

    const[activeFaq, setActiveFaq] = useState({btn0 : false})

    const [faqs, setFaqs] = useState([]); // initial state for FAQs before API call

    let faqServices = new SiteService(); // instantiating the class

    const allFaqData = () => {
        return faqServices.faqData();
    };

    useEffect(() => {
        allFaqData().then((data) => setFaqs(data.data));
    }, []);

  return (
    <>
        <section className="row_am faq_section">
        {gredient && <div className="faq_bg"> <img src="assets/images/section-bg.png" alt="image"/> </div>}
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2><span>FAQ</span> - Frequently Asked Questions</h2>
                    <p>Organizing all types of wellness activity you desire.<br/>Proven step to increase pleasure and commitment.</p>
                </div>
                <div className="faq_panel">
                    <div className="accordion" id="accordionMyfitFaq">

                        {/* displays loading ... while the page fetches the FAQs */}
                        {faqs.length < 1 && <h3>Loading...</h3>}

                        {faqs.length > 0 && faqs.map((faq, index) => {
                            let id = `heading${index}`; //headingSeven
                            let target = `collapse${index}`; //#collapseSeven

                            return(
                            <div key={index} className="card" data-aos="fade-up">
                                <div className="card-header" id={id}>
                                    <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link collapsed ${activeFaq[`btn${index}`] && "active"}`} onClick= {() => setActiveFaq(activeFaq[`btn${index}`] ? {[`btn${index}`] : false} : {[`btn${index}`] : true})} data-toggle="collapse"
                                        data-target={`#${target}`}>{activeFaq[`btn${index}`] ? <i className="icon_faq icofont-minus"></i>  : <i className="icon_faq icofont-plus"></i>}{faq.title}
                                    </button>
                                    </h2>
                                </div>

                                <div id={target} className="collapse" aria-labelledby={id} data-parent="#accordionMyfitFaq">
                                    <div className="card-body">
                                        <p>{faq.text}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main
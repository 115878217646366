import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom'
import Bredcrumb from '../../Bredcrumb/Main'
import BGImg from "../../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png"
import { useParams } from "react-router-dom";
import SiteService from "../../../vendors/service/siteService";


const Main = ({brdcum}) => {
    const { id } = useParams();
    const location = useLocation();
    const [blogData, setBlogData] = useState([]);
    const [newAllData, setNewAllData] = useState([]);
   

  useEffect(() => {
    getBlogData();
    getLatestBlog();
  }, [location.state?.data]);

  const api = new SiteService();
  const data = location.state?.data;
  const allData = location.state?.allData;
  
  
  const getLatestBlog = () =>{
    const arrData = [ ...allData ];
    const itemIndex = arrData.findIndex(x => x.id === data.id)
     arrData.splice(itemIndex, 1);
    const newAllData = arrData.splice(0, 3);
    setNewAllData(newAllData);

    console.log('All data ', arrData, newAllData);
}

  const getBlogData = async () => {
    try {
      const res = await api.blogData();
      setBlogData(res.data);
      
    } catch (error) {
      console.log("Error from blog data ", error);
    }
  };
        
  return (
    <>
        
        {brdcum.b1 && 
        <Bredcrumb 
        no={1}
        title="Blog details" 
        tag="Blog single" 
        bgimg={BGImg}/>}

        {brdcum.b2 && 
        <Bredcrumb 
        no={2}
        title="Blog details" 
        tag="Blog single" 
        bgimg={BGImg1}/>}

        {brdcum.b5 && 
        <Bredcrumb 
        no={5}
        title="Blog details" 
        tag="Blog single" 
        bgimg={BGImg}/>}

        {brdcum.b3 && 
        <Bredcrumb 
        no={3}
        title="Blog details" 
        tag="Blog single" />}

        {brdcum.b4 && 
        <Bredcrumb 
        no={4}
        title="Blog details" 
        tag="Blog single" 
        bgimg={BGImg2}/>}

        <section className="blog_detail_section">
            <div className="container">
                <div className="blog_inner_pannel">
                    <div className="review">
                        <span>Review</span>
                        {/* <span>45 min ago</span> */}
                        <span>{new Date (data && data.post_modified).toDateString()}</span>

                    </div>
                    <div className="section_title">
                        <h2>{data && data.post_title}</h2>
                    </div>
                    <div className="main_img">
                        <img src={data && data.meta_value} alt="image" />
                    </div>
                    <div className="info">
                        <h3>{data && data.post_name}</h3>
                        <div dangerouslySetInnerHTML={{__html: data && data.post_content}}></div>
                    </div>

                   
                    <div className="blog_authore">
                        <div className="authore_info">
                            {/* <div className="avtar">
                                <img src="assets/images/blog_d02.png" alt="image" />
                            </div> */}
                            <div className="text">
                                <h3>{data && data.post_author}</h3>
                                <span>{new Date (data && data.post_date).toDateString()}</span>
                            </div>
                        </div>
                        <div className="social_media">
                            <ul>
                                <li>
                                <a href={process.env.REACT_APP_FACEBOOK}>
                                    <i className="icofont-facebook"></i>
                                </a>
                                </li>
                                <li>
                                <a href={process.env.REACT_APP_TWITTER}>
                                    <i className="icofont-twitter"></i>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="blog_tags">
                        <a href={process.env.REACT_APP_BLOGSITE}>Visit Blog Site </a>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className="row_am comment_section">
            <div className="container">
                <div className="section_title">
                    <h2>3 Comments</h2>
                </div>
                <ul>
                    <li>
                        <div className="authore_info">
                        <div className="avtar">
                            <img src="assets/images/blog_d01.png" alt="image" />
                        </div>
                        <div className="text">
                            <span>30 min ago</span>
                            <h4>Dolly Shell</h4>
                        </div>
                        </div>
                        <div className="comment">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the in
                            dustrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen. </p>
                        </div>
                    </li>
                    <li className="replay_comment">
                        <div className="authore_info">
                        <div className="avtar">
                            <img src="assets/images/blog_d02.png" alt="image" />
                        </div>
                        <div className="text">
                            <span>15 min ago</span>
                            <h4>Devil Joe</h4>
                        </div>
                        </div>
                        <div className="comment">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the when.</p>
                        </div>
                    </li>
                    <li>
                        <div className="authore_info">
                        <div className="avtar">
                            <img src="assets/images/blog_d03.png" alt="image" />
                        </div>
                        <div className="text">
                            <span>2 days ago</span>
                            <h4>Sherly Shie</h4>
                        </div>
                        </div>
                        <div className="comment">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the in
                            dustrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen. </p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <section className="row_am comment_form_section">
            <div className="container">
                <div className="section_title">
                    <h2>Leave a <span>comment</span></h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>
                <form action="">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Name *" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email *" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Phone" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Website  " />
                        </div>
                        </div>
                        <div className="col-md-12">
                        <div className="form-group">
                            <textarea className="form-control" placeholder="Comments"></textarea>
                        </div>
                        </div>
                        <div className="col-md-12 text-center">
                        <button className="btn puprple_btn" type="submit">POST COMMENTS</button>
                        </div>
                    </div>
                </form>
            </div>
        </section> */}

        <section className="row_am latest_story" id="blog">
            <div className="container">
                <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <h2>Read latest <span>story</span></h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p>
                </div>
                <div className="row">

                 {newAllData.map((data) => {
                    
                    return (

                     <div className="col-md-4">
                     <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                         <div className="story_img">
                        <img src={data.meta_value} alt="image" />
                         <span>45 min ago</span>
                         </div>
                         <div className="story_text">
                            <h3>{data.post_title}</h3>
                             <div dangerouslySetInnerHTML={{__html: data.post_content.substring(0,300)+'  . . .'}}></div>
                            {/* <Link to={`/blog-single/${data.id}`} state={{data}}>
                                 READ MORE
                            </Link> */}
                            <Link to={`/blogdetails/${data.id}`} state={{data, allData: blogData}}>
                                 READ MORE
                            </Link>
                         </div>
                     </div>
                 </div>

                 )})}

                    {/* <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src="assets/images/story02.png" alt="image" />
                            <span>45 min ago</span>
                            </div>
                            <div className="story_text">
                                <h3>Top rated app! Yupp.</h3>
                                <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                                <Link to="#">READ MORE</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src="assets/images/story03.png" alt="image" />
                            <span>45 min ago</span>
                            </div>
                            <div className="story_text">
                                <h3>Creative ideas on app.</h3>
                                <p>Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.</p>
                                <Link to="#">READ MORE</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    </>
  )
}

export default Main
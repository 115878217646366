import React from 'react'
import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/functional.png'
import img3 from '../../../assets/images/live-chat.png'
import img4 from '../../../assets/images/support.png'
import img5 from '../../../assets/images/features_frame.png'

const Main = ({video}) => {
  return (
    <>
        {<section className="row_am features_section" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2><span>Features</span> that makes app different!</h2>
                <p>Fitness Integration with your health plan in your pocket bolsters<br/>healthier life by tracking and experiencing life-changing results.</p>
                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
    
                    <div className="text">
                        <h4>Health Tips</h4>
                        <p>myFit continuously help you organize both general and the few health questions relevant to you. </p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
        
                    <div className="text">
                        <h4>Health Statistics</h4>
                        <p>Collect your health statistics yourself, weight changes, blood pressure, blood glucose or data that fits you specifically.</p>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">

                    <div className="text">
                        <h4>Reminders</h4>
                        <p>Miss no appointment, medication schedule and more with myFit reminders. Allow your provider or simply set up whatever you needed reminding for.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
   
                    <div className="text">
                        <h4>Health Plan</h4>
                        <p>Your health plan your way - myFit assist you organizing your prescription, health routines, your providers treatment plans in one place.</p>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={img5} alt="image" />
                </div>
                </div>
            </div>
        </section>}
    </>
  )
}

export default Main
import { Link } from 'react-router-dom'
import React , { useState, useEffect} from 'react'
import AOS from "aos";
import img1 from '../../../assets/images/standard.png'
import img2 from '../../../assets/images/unlimited.png'
import img3 from '../../../assets/images/premium.png'

import SiteService from '../../../vendors/service/siteService'

const Main = ({gredient , video}) => {

    const[tog, setTog] = useState()

    const [prices, setPrices] = useState([]); // initial state for FAQs before API call

    const [pricing_text_02, setPricingtext02] = useState('');

    let priceServices = new SiteService(); // instantiating the class

    const getAllPriceData = () => {
        return priceServices.priceData();
    };

    useEffect(() => {
        getAllPriceData().then((data) => {
            setPricingtext02(data.data.pricing_text_02);
            setPrices(data.data.pricing) });
        AOS.init();
        AOS.refresh();
    }, []);

  return (
    <>
        {gredient ?
            <section className="row_am pricing_section gredient-pricing" id="pricing">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>Flexible & Simple <span>pricing</span></h2>
                    <p className="gredient-p">
                    {/*    <>*/}
                    {/*    {pricing_text_02}*/}
                    {/*</>*/}
                         Choose your myFit Pricing Plan <br/> myFit has a free Standard for Basics , $5 per month for Premium <br />
                        and $7.99 per month for Gold-tier</p>
                    </div>
                    <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                        <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                        <div className="tog_block">
                            <span className={`tog_btn ${tog && "month_active"}`} onClick = {() => setTog(tog === true ? false : true)}></span>
                        </div>
                        <span className="years">Yearly</span>
                        {/* <span className="offer">50% off</span> */}
                    </div>

                    <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                        <div className="row">

                        {prices.length > 0 &&
                                prices.map((price, index) => {
                                        return (
                                            <div key={index} className="col-md-4">
                                                <div className={`pricing_block ${index % 2 == 0? '' : 'highlited_block'}`}>
                                                    <div className="icon">
                                                    { video ? <img src="assets/images/standard-one.png" alt="image" /> : <img src={price.icon} alt="image" /> }
                                                    </div>
                                                    <div className="pkg_name">
                                                    <h3>{price.title}</h3>
                                                    <span>{price.text}</span>
                                                    </div>
                                                    <span className="price">{price.price}</span>
                                                    <ul className="benifits">
                                                        {price.features.map((feature, index)=> <li key={index}><p>{feature}</p></li>)}
                                                    </ul>
                                                    <Link to="/" className="btn white_btn">Start Now</Link>
                                                </div>
                                            </div>
                                        );
                                })
                            }

                            {/* <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                    <img src="assets/images/unlimited-one.png" alt="image"/>
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Unlimited</h3>
                                    <span>For the professionals</span>
                                    </div>
                                    <span className="price">$99</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Unlimited Website</p>
                                    </li>
                                    <li>
                                        <p>200 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>20 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>10 Domains access</p>
                                    </li>
                                    <li>
                                        <p>24/7 Customer support</p>
                                    </li>
                                    </ul>
                                    <Link to="/" className="btn white_btn">Start Now</Link>
                                </div>
                            </div> */}

                        </div>
                    </div>

                    <div className={`pricing_pannel yearly_plan ${tog ? 'active' : ''}`} >
                        <div className="row">

                        {prices.length > 0 &&
                                prices.map((price, index) => {
                                        return (
                                            <div key={index} className="col-md-4">
                                                <div className={`pricing_block ${index % 2 == 0? '' : 'highlited_block'}`}>
                                                    <div className="icon">
                                                    { video ? <img src="assets/images/standard-one.png" alt="image" /> : <img src={price.icon} alt="image" /> }
                                                    </div>
                                                    <div className="pkg_name">
                                                    <h3>{price.title}</h3>
                                                    <span>{price.text}</span>
                                                    </div>
                                                    <span className="price">{price.price_anual}</span>
                                                    <ul className="benifits">
                                                        {price.features.map((feature, index)=> <li key={index}><p>{feature}</p></li>)}
                                                    </ul>
                                                    <Link to="/" className="btn white_btn">Start Now</Link>
                                                </div>
                                            </div>
                                        );
                                })
                            }

                        </div>
                    </div>

                    <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">Not sure what to choose ? <Link to="/contact">contact us</Link> for custom packages</p>
                </div>
            </section>
        :
            <section className="row_am pricing_section" id="pricing">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>Flexible & Simple <span>pricing</span></h2>
                    <p>Choose your myFit Pricing Plan <br/> myFit has a free Standard for Basics , $5 per month for Premium <br />
                        and $7.99 per month for Gold-tier.</p>
                    </div>
                    <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                        <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                        <div className="tog_block">
                            <span className={`tog_btn ${tog && "month_active"}`} onClick = {() => setTog(tog === true ? false : true)}></span>
                        </div>
                        <span className="years">Yearly</span>
                        {/* <span className="offer">50% off</span> */}
                    </div>

                    <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                        <div className="row">

                            {prices.length > 0 &&
                                prices.map((price, index) => {
                                        return (
                                            <div key={index} className="col-md-4">
                                                <div className={`pricing_block ${index % 2 == 0? '' : 'highlited_block'}`}>
                                                    <div className="icon">
                                                    { video ? <img src="assets/images/standard-one.png" alt="image" /> : <img src={price.icon} alt="image" /> }
                                                    </div>
                                                    <div className="pkg_name">
                                                    <h3>{price.title}</h3>
                                                    <span>{price.text}</span>
                                                    </div>
                                                    <span className="price">{price.price}</span>
                                                    <ul className="benifits">
                                                        {price.features.map((feature, index)=> <li key={index}><p>{feature}</p></li>)}
                                                    </ul>
                                                    <Link to="/" className="btn white_btn">Start Now</Link>
                                                </div>
                                            </div>
                                        );
                                   })
                            }

                        </div>
                    </div>

                    <div className={`pricing_pannel yearly_plan ${tog ? 'active' : ''}`} >
                        <div className="row">

                        {prices.length > 0 &&
                                prices.map((price, index) => {
                                        return (
                                            <div key={index} className="col-md-4">
                                                <div className={`pricing_block ${index % 2 == 0? '' : 'highlited_block'}`}>
                                                    <div className="icon">
                                                    { video ? <img src="assets/images/standard-one.png" alt="image" /> : <img src={price.icon} alt="image" /> }
                                                    </div>
                                                    <div className="pkg_name">
                                                    <h3>{price.title}</h3>
                                                    <span>{price.text}</span>
                                                    </div>
                                                    <span className="price">{price.price_anual}</span>
                                                    <ul className="benifits">
                                                        {price.features.map((feature, index)=> <li key={index}><p>{feature}</p></li>)}
                                                    </ul>
                                                    <Link to="/" className="btn white_btn">Start Now</Link>
                                                </div>
                                            </div>
                                        );
                                })
                            }

                        </div>
                    </div>

                    <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">Not sure what to choose ? <Link to="/contact">contact us</Link> for custom packages</p>
                </div>
            </section>
        }
    </>
  )
}

export default Main
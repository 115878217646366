import { Link } from "react-router-dom";
import React, { useState } from "react";
import img from "../../../assets/images/anim_line.png";
import blueapp from "../../../assets/images/appstore_blue.png";
import blue from "../../../assets/images/googleplay_blue.png";
import screen from "../../../assets/images/download-screen01.png";
import screen1 from "../../../assets/images/download-screen02.png";

const Main = () => {
  const [animate, setAnimate] = useState(false);
  const [purple, setPurple] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const element = document.getElementsByClassName("free_text")[0];
//       const position = element.getBoundingClientRect();

//       if (position.top < window.innerHeight && position.bottom >= 0) {
//         document.getElementsByClassName("purple_backdrop")[0].style.opacity =
//           "1";
//       } else {
//         document.getElementsByClassName("purple_backdrop")[0].style.opacity =
//           "0";
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

  return (
    <>
      <section
        className={`row_am free_app_section review_freeapp ${
          animate && "aos-animate"
        }`}
        id="getstarted"
      >
        <div className="container">
          <div
            className="free_app_inner"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <div className="anim_line dark_bg">
              {[...Array(9)].map((_, index) => (
                <span key={index}>
                  <img src={img} alt="anim_line" />
                </span>
              ))}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="free_text">
                  <div className="section_title">
                    <h2>Let’s download free from apple and play store</h2>
                    <p>
                      Instant free download from apple and play store. All you
                      need is an iPhone or Android device to enjoy all
                      personalized metrics for personal use. Welcome to myFit
                      App.
                    </p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a href={process.env.REACT_APP_ANDROID_URL}>
                        <img src={blueapp} alt="image" />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_IOS_URL}>
                        <img src={blue} alt="image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="free_img">
                  <img src={screen} alt="image" />
                  <img className="mobile_mockup" src={screen1} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="purple_backdrop"></div> */}
    </>
  );
};

export default Main;

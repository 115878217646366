import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import story1 from '../../../assets/images/story01.png';
// import story2 from '../../../assets/images/story02.png';
// import story3 from '../../../assets/images/story03.png';
import SiteService from "../../../vendors/service/siteService";


const Main = () => {
    const [blogData, setBlogData] = useState([]);

  let api = new SiteService();
  useEffect(() => {
    getBlogData();
  }, []);

  const getBlogData = async () => { 
      try {
        let res = await api.blogData();
        setBlogData(res.data);
       
      } catch (error) {
        console.log("Error from blog data ", error);
      }    
  };
  
  return (
    <>
        <section className="row_am latest_story" id="blog">
            <div className="container">
                <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <h2>Read latest <span>story</span></h2>
                </div>
                <div className="row">
                  
                {blogData.slice(1, 4).map((data, index) => (
              <div
                className="col-md-4"
                key={index}>
                <div
                  className="story_box"
                  data-aos="fade-up"
                  data-aos-duration="1500">
                  <div className="story_img">
                    <img
                      src={data.meta_value}
                      alt="image"
                    />
                    <span>{new Date(data.post_date).toDateString()}</span>
                  </div>
                  <div className="story_text">
                    <h3>{data.post_title}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.post_content.substring(0, 100) + "  . . .",
                      }}></div>
                      <Link to={`/blogdetails/${data.id}`} state={{data, allData: blogData}}>
                                 READ MORE
                            </Link>
                    {/* <Link to={`/blogdetails/${item.id}`}>READ MORE</Link> */}
                  </div>
                </div>
              </div>
            ))}
                    {/* <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story1} alt="image" />
                            <span>45 min ago</span>
                            </div>
                            <div className="story_text">
                                <h3>Cool features added!</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting 
                                industry lorem Ipsum has.</p>
                                <Link to="/blog-single">READ MORE</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story2} alt="image" />
                            <span>45 min ago</span>
                            </div>
                            <div className="story_text">
                                <h3>Top rated app! Yupp.</h3>
                                <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                                <Link to="/blog-single">READ MORE</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="story_img">
                            <img src={story3} alt="image" />
                            <span>45 min ago</span>
                            </div>
                            <div className="story_text">
                                <h3>Creative ideas on app.</h3>
                                <p>Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.</p>
                                <Link to="/blog-single">READ MORE</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section> 
    </>
  )
}

export default Main
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import React from "react";
import line from "../../assets/images/anim_line.png";
import blueapp from "../../assets/images/appstore_blue.png";
import blue from "../../assets/images/googleplay_blue.png";
import logo from "../../assets/images/logo_white.png";
import top from "../../assets/images/go_top.png";

const Main = ({ footer }) => {
  return (
    <>
      {footer.f1 && (
        <footer>
          <div className="top_footer" id="contact">
            <div className="anim_line dark_bg">
              {[...Array(9)].map((_, index) => (
                <span key={index}>
                  <img src={line} alt="anim_line" />
                </span>
              ))}
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src={logo} alt="image" />
                    </div>
                    <ul>
                      <li>
                        {/* <Link to="#">
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </Link> */}
                        <a
                          href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </a>
                      </li>
                      <li>
                        {/* <Link to="#">{process.env.REACT_APP_US_PHONE}</Link> */}
                        <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                          {process.env.REACT_APP_US_PHONE}
                        </a>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a href={process.env.REACT_APP_FACEBOOK}>
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_TWITTER}>
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        {/* <Link to="/">Home</Link> */}
                        <HashLink to="/#">Home</HashLink>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Support</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <HashLink to="/#how_it_work">How it works</HashLink>
                      </li>
                      <li>
                        <Link to="/terms">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try Out</h3>
                    <ul className="app_btn">
                      <li>
                        <a href={process.env.REACT_APP_ANDROID_URL}>
                          <img src={blueapp} alt="image" />
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_IOS_URL}>
                          <img src={blue} alt="image" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    © Copyrights {process.env.REACT_APP_YEAR}. All rights
                    reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="developer_text">.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="go_top">
            <span>
              <img src={top} alt="image" />
            </span>
          </div>
        </footer>
      )}

      {footer.f2 && (
        <footer>
          <div className="top_footer top_footer-dark" id="contact">
            <div className="container">
              <span className="banner_shape1">
                {" "}
                <img
                  src="assets/images/banner-shape-one1.png"
                  alt="image"
                />{" "}
              </span>
              <span className="banner_shape2">
                {" "}
                <img
                  src="assets/images/banner-shape2two.png"
                  alt="image"
                />{" "}
              </span>
              <span className="banner_shape3">
                {" "}
                <img
                  src="assets/images/banner-shapethree3.png"
                  alt="image"
                />{" "}
              </span>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src="assets/images/footer_logo.png" alt="image" />
                    </div>
                    <ul>
                      <li>
                        {/* <Link to="#">
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </Link> */}
                        <a
                          href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </a>
                      </li>
                      <li>
                        {/* <Link to="#">{process.env.REACT_APP_US_PHONE}</Link> */}
                        <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                          {process.env.REACT_APP_US_PHONE}
                        </a>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a href={process.env.REACT_APP_FACEBOOK}>
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_TWITTER}>
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Support</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <HashLink to="/#how_it_work">How it works</HashLink>
                      </li>
                      <li>
                        <Link to="/terms">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try Out</h3>
                    <ul className="app_btn">
                      <li>
                        <a href={process.env.REACT_APP_ANDROID_URL}>
                          <img src={blueapp} alt="image" />
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_IOS_URL}>
                          <img src={blue} alt="image" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer bottom_footer-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    © Copyrights {process.env.REACT_APP_YEAR}. All rights
                    reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="developer_text">.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="go_top">
            <span>
              <img src="assets/images/go_top.png" alt="image" />
            </span>
          </div>
        </footer>
      )}

      {footer.f3 && (
        <footer className="gredient-footer">
          <div className="footer_bg">
            {" "}
            <img src="assets/images/section-bg.png" alt="image" />{" "}
          </div>
          <div className="top_footer" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img
                        src="assets/images/footer_logo-one.png"
                        alt="image"
                      />
                    </div>
                    <ul>
                      <li>
                        {/* <Link to="#">
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </Link> */}
                        <a
                          href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </a>
                      </li>
                      <li>
                        {/* <Link to="#">{process.env.REACT_APP_US_PHONE}</Link> */}
                        <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                          {process.env.REACT_APP_US_PHONE}
                        </a>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a href={process.env.REACT_APP_FACEBOOK}>
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_TWITTER}>
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="#">Services</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Suport</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="#">Support</Link>
                      </li>
                      <li>
                        <HashLink to="/#how_it_work">How it works</HashLink>
                      </li>
                      <li>
                        <Link to="/terms">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try Out</h3>
                    <ul className="app_btn">
                      <li>
                        <a href={process.env.REACT_APP_ANDROID_URL}>
                          <img src={blueapp} alt="image" />
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_IOS_URL}>
                          <img src={blue} alt="image" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    © Copyrights {process.env.REACT_APP_YEAR}. All rights
                    reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="developer_text">.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="go_top">
            <span>
              <img src="assets/images/go_top-one.png" alt="image" />
            </span>
          </div>
        </footer>
      )}

      {footer.f4 && (
        <footer className="video-footer-two">
          <div className="top_footer" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src="assets/images/footer_logo.png" alt="image" />
                    </div>
                    <ul>
                      <li>
                        {/* <Link to="#">
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </Link> */}
                        <a
                          href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </a>
                      </li>
                      <li>
                        {/* <Link to="#">{process.env.REACT_APP_US_PHONE}</Link> */}
                        <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                          {process.env.REACT_APP_US_PHONE}
                        </a>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a href={process.env.REACT_APP_FACEBOOK}>
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_TWITTER}>
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="#">Services</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Support</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="#">Support</Link>
                      </li>
                      <li>
                        <HashLink to="/#how_it_work">How it works</HashLink>
                      </li>
                      <li>
                        <Link to="/terms">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try Out</h3>
                    <ul className="app_btn">
                      <li>
                        <a href={process.env.REACT_APP_ANDROID_URL}>
                          <img src={blueapp} alt="image" />
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_IOS_URL}>
                          <img src={blue} alt="image" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    © Copyrights {process.env.REACT_APP_YEAR}. All rights
                    reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="developer_text">.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="go_top">
            <span>
              <img src="assets/images/go_top-one.png" alt="image" />
            </span>
          </div>
        </footer>
      )}

      {footer.f5 && (
        <footer>
          <div className="top_footer footer-wave" id="contact">
            <div className="container">
              <span className="banner_shape1">
                {" "}
                <img src="assets/images/banner-shape1.png" alt="image" />{" "}
              </span>
              <span className="banner_shape2">
                {" "}
                <img src="assets/images/banner-shape2.png" alt="image" />{" "}
              </span>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src="assets/images/footer_logo.png" alt="image" />
                    </div>
                    <ul>
                      <li>
                        {/* <Link to="#">
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </Link> */}
                        <a
                          href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </a>
                      </li>
                      <li>
                        {/* <Link to="#">{process.env.REACT_APP_US_PHONE}</Link> */}
                        <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                          {process.env.REACT_APP_US_PHONE}
                        </a>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a href={process.env.REACT_APP_FACEBOOK}>
                          <i className="icofont-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_TWITTER}>
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="#">Services</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Support</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="#">Support</Link>
                      </li>
                      <li>
                        <HashLink to="/#how_it_work">How it works</HashLink>
                      </li>
                      <li>
                        <Link to="/terms">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try Out</h3>
                    <ul className="app_btn">
                      <li>
                        <a href={process.env.REACT_APP_ANDROID_URL}>
                          <img src={blueapp} alt="image" />
                        </a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_IOS_URL}>
                          <img src={blue} alt="image" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    © Copyrights {process.env.REACT_APP_YEAR}. All rights
                    reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="developer_text">.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="go_top">
            <span>
              <img src="assets/images/go_top.png" alt="image" />
            </span>
          </div>
        </footer>
      )}
    </>
  );
};

export default Main;

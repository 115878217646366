import React, { useState , useEffect} from 'react'
import Trusted from '../HomeMain/Trusted/Main'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"

import About1 from '../../assets/images/about_one.png'
import About2 from '../../assets/images/about_two.png'
import About3 from '../../assets/images/about_three.png'

import AboutMainImg from '../../assets/images/about_main_image.png'

const Main = ({brdcum}) => {
    const [ytShow , setytShow] = useState (false)

    // About us text variables
    let innovativeDesc = `The team at myFit by Fluxtra LLC works with you to achieve your health objectives without getting in your way. Do your health your way to get the best result for you and your family. We are you, always ready and always engaged with the users to help get the best out of our solution. 
    We work with you to achieve your health and fitness goals by providing solutions that fit your goals.`

    let whyWeAreDiff = {
        desc:'We work with you to achieve your health and fitness goals by providing solutions that fit your goals.',
        secureDesc:"MyFit is built to keep your data secure and protect your privacy. Your data is encrypted and you are always in control of your information.",
        richFeaturesDesc: " Self-service & Scheduling, Tracking of health and fitness, Performance metrics. A more active, healthier interaction and improved quality of life.",
        easeOfUseDesc:" MyFit keeps you connected to your health, a healthy partner for a healthy life. Measure and keep track of your health & awareness in one place.",
        supportDesc:" myFit alerts you of irregular rhythms. You can connect with your health facilitator.",
        title1: 'Secure',
        title2: 'Rich Features',
        title3: 'Ease of Use',
        title4: 'Support'
    }
    let queryDesc = 'If you have any questions, please get in touch with us, we will revert back quickly.'
    
  return (
    <>       
       {brdcum.b1 && 
        <Bredcrumb 
        no={1}
        title="About Us" 
        tag="About us" 
        bgimg={BGImg}/>}

        {brdcum.b2 && 
        <Bredcrumb 
        no={2}
        title="About Us" 
        tag="About us" 
        bgimg={BGImg1}/>}

        {brdcum.b5 && 
        <Bredcrumb 
        no={5}
        title="About Us" 
        tag="About us" 
        bgimg={BGImg}/>}


        {brdcum.b3 && 
        <Bredcrumb 
        no={3}
        title="About Us" 
        tag="About us" />}

        {brdcum.b4 && 
        <Bredcrumb 
        no={4}
        title="About Us" 
        tag="About us" 
        bgimg={BGImg2}/>}

        <section className="row_am app_solution_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="app_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Providing innovative app solution</span> to make customer
                            life easy to grow.</h2>
                        </div>
                        <p data-aos="fade-up" data-aos-duration="1500">
                            {innovativeDesc}
                        </p>
                        {/* <p data-aos="fade-up" data-aos-duration="1500">
                            Standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to
                            make a type specien book. It has survived not only five centuries, but also the leap into electronic
                            typesetting.
                        </p> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="app_images" data-aos="fade-in" data-aos-duration="1500">
                        <ul>
                            <li><img src={About1} alt="health monitor gadget" /></li>
                            <li>
                            <a data-url="#" onClick = {() => setytShow(true)}  className="popup-youtube play-button"
                                data-toggle="modal"
                                data-target="#myModal" title="About Video">
                                <img src={About2} alt="" />
                                <div className="waves-block">
                                <div className="waves wave-1"></div>
                                <div className="waves wave-2"></div>
                                <div className="waves wave-3"></div>
                                </div>
                                <span className="play_icon"><img src="assets/images/play_black.png" alt="image"/></span>
                            </a>
                            </li>
                            {/* <li><img src="assets/images/abt_03.png" alt="" /></li> */}
                            <li><img src={About3} alt="" /></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="row_am why_we_section" data-aos="fade-in">
            <div className="why_inner">
                <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <h2><span>Why we are different</span> from others!</h2>
                    <p>{whyWeAreDiff.desc}</p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                    <div className="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                        {/* <div className="icon">
                        <img src="assets/images/secure.png" alt="image"/>
                        </div> */}
                        <div className="text">
                        <h3>{whyWeAreDiff.title1}</h3>
                        <p> {whyWeAreDiff.secureDesc}</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                        {/* <div className="icon">
                        <img src="assets/images/abt_functional.png" alt="image"/>
                        </div> */}
                        <div className="text">
                        <h3>{whyWeAreDiff.title2}</h3>
                         <p>{whyWeAreDiff.richFeaturesDesc}</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        {/* <div className="icon">
                        <img src="assets/images/communication.png" alt="image"/>
                        </div> */}
                        <div className="text">
                        <h3>{whyWeAreDiff.title3}</h3>
                         <p>{whyWeAreDiff.easeOfUseDesc}</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400">
                        {/* <div className="icon">
                        <img src="assets/images/abt_support.png" alt="image"/>
                        </div> */}
                        <div className="text">
                        <h3>{whyWeAreDiff.title4}</h3>
                        <p>{whyWeAreDiff.supportDesc}</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className="row_am about_app_section about_page_sectino">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="abt_img" data-aos="fade-in" data-aos-duration="1500">
                            <img src={AboutMainImg} alt="image"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2> <span>We focus on quality,</span> never
                            focus on quantity</h2>
                            
                            <p>{innovativeDesc}</p>
                        </div>
                        <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                            <li>
                            {/* <div className="icon">
                                <img src="assets/images/download.png" alt="image" />
                            </div> */}
                            <div className="text">
                                <h3>Manage</h3>
                                <p>health plan</p>
                            </div>
                            
                            </li>
                            <li>
                            {/* <div className="icon">
                                <img src="assets/images/followers.png" alt="image" />
                            </div> */}
                            <div className="text">
                                <h3>Health Record</h3>
                                <p>with you</p>
                            </div>
                            </li>
                            <li>
                            {/* <div className="icon">
                                <img src="assets/images/reviews.png" alt="image" />
                            </div> */}
                            <div className="text">
                                <h3>Health</h3>
                                <p>reminders</p>
                            </div>
                            </li>
                            <li>
                            {/* <div className="icon">
                                <img src="assets/images/countries.png" alt="image" />
                            </div> */}
                            <div className="text">
                                <h3>Quick</h3>
                                <p>Schedule</p>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
        <section className="row_am query_section">
            <div className="query_inner" data-aos="fade-in" data-aos-duration="1500">
                <div className="container">
                    <span className="banner_shape1"> <img src="assets/images/banner-shape1.png" alt="image" /> </span>
                    <span className="banner_shape2"> <img src="assets/images/banner-shape2.png" alt="image" /> </span>
                    <span className="banner_shape3"> <img src="assets/images/banner-shape3.png" alt="image" /> </span>
                
                    <div className="section_title">
                        <h2>Have any questions about ?</h2>
                        <p>{queryDesc}</p>
                    </div>
                    <Link to="/contact" className="btn white_btn">CONTACT US NOW</Link>
                </div>
            </div>
        </section>
        

    </>
    
  )
}

export default Main

/** @format */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";
import SuccessImg from "../../assets/images/confirmed-letter.jpg"

import SiteService from "../../vendors/service/siteService";

// Form Inputs
const FormInput = (props) => {
  let {
    name,
    type,
    placeholder,
    required,
    maxLenght,
    errorMessage,
    value,
    onChange,
    pattern,
  } = props;

  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    return setFocused(true);
  };

  return (
    <div className="form-group">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="form-control"
        maxLength={maxLenght}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={handleFocus}
        pattern={pattern}
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </div>
  );
};

const Main = ({ brdcum }) => {
  let response = document.getElementById("contact_loader");
  let errText = document.getElementById("errText");
  let navigate = useNavigate();

  let [showSuccessfulImage, setShowSuccessfulImage] = useState(false) // Handles when sucess image is shown

  // Form Validation
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    country: "",
  }); //initial state values
  const [countries, setCountries] = useState([]); // initial state for country dropdown

  const [myData, setMyData] = useState(null);

  // Gave a generic name for multiple calls
  const API_CALL = new SiteService(); // instantiating the class
  // API CALL
  const allCountry = () => {
    return API_CALL.countryData();
  };
  const contactForm = async (value) => {
    return await API_CALL.contactData(value);
  };

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Submitting form
  const handleSubmit = async (e) => {
    e.preventDefault();

    response.innerHTML = `<div
    class="loader loader-sm"
    id="loader-1" style='margin-right: 20px'></div>`;

    contactForm(values)
      .then((contact) => {
        setMyData(contact);

        // Checking for errors
        if (contact?.data?.status < 1 || contact?.data?.message_id == ""){
          errText.textContent = "unable to send your message, please try again";
          response.innerHTML = `<p> SEND MESSAGE </p>`;
        }else {
          response.innerHTML = `<p> SEND MESSAGE </p>`;

          setShowSuccessfulImage(true) // sets show success image to true

          setTimeout(() => {
            navigate("/");
          }, 10000);
        }
      })
      .catch(() => {
        errText.textContent = "unable to send your message, please try again";
        response.innerHTML = `<p> SEND MESSAGE </p>`;
      });
  };

  //CALLS THE API AFTER COMPONENT LOADS
  useEffect(() => {
    allCountry().then((data) => setCountries(Object.entries(data.data))).catch((err)=>console.log(err.message));
  }, []);


  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="Contact Us"
          paragraph="If you have an query, please get in touch with us, we will revert back quickly."
          tag="Contact us"
          bgimg={BGImg}
        />
      )}

      {brdcum.b2 && (
        <Bredcrumb
          no={2}
          title="Contact Us"
          paragraph="If you have an query, please get in touch with us, we will revert back quickly."
          tag="Contact us"
          bgimg={BGImg1}
        />
      )}

      {brdcum.b5 && (
        <Bredcrumb
          no={5}
          title="Contact Us"
          paragraph="If you have an query, please get in touch with us, we will revert back quickly."
          tag="Contact us"
          bgimg={BGImg}
        />
      )}

      {brdcum.b3 && (
        <Bredcrumb
          no={3}
          title="Contact Us"
          paragraph="If you have an query, please get in touch with us, we will revert back quickly."
          tag="Contact us"
        />
      )}

      {brdcum.b4 && (
        <Bredcrumb
          no={4}
          title="Contact Us"
          paragraph="If you have an query, please get in touch with us, we will revert back quickly."
          tag="Contact us"
          bgimg={BGImg2}
        />
      )}

      <section
        className="contact_page_section"
        id="contact">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form" id="contact_body">
              {showSuccessfulImage ? (
                <>
                  <div><img className="img-fluid animate-image" src={SuccessImg} alt='success image' /></div>
                  <p>We have received your message and will follow up promptly.</p>
                </>
              ) : (
                <>
                <div className="section_title">
                  <h2>
                    <span>Leave a</span> message
                  </h2>
                  <p>Fill up form below, our team will get back soon</p>
                </div>

                {/* Contact Form */}
                <form onSubmit={handleSubmit}>
                  <FormInput
                    name="name"
                    type="text"
                    placeholder="Name"
                    errorMessage="Please enter your first and last name. (e.g: Mark John)"
                    required={true}
                    maxLenght={35}
                    value={values.name}
                    onChange={onChange}
                    pattern="^\w+( \w+)$"
                  />

                  <FormInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    errorMessage="It should be a valid email address!"
                    required={true}
                    maxLenght={35}
                    value={values.email}
                    onChange={onChange}
                  />

                  <div className="form-group">
                    <select
                      className="form-control"
                      name="country"
                      value={values.country}
                      onChange={onChange}
                      required>
                      <option value={""}>
                        Country
                      </option>
                      {countries.length > 0 &&
                        countries.map((country, index) => (
                          <option
                            key={index}
                            value={country[0]}>
                            {country[1]}
                          </option>
                        ))}
                    </select>
                  </div>

                  <FormInput
                    name="phone"
                    type="number"
                    placeholder="Phone"
                    errorMessage="It should be a valid phone number!"
                    maxLenght={15}
                    value={values.phone}
                    onChange={onChange}
                    pattern="^[0-9]{15}$"
                  />

                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Your message"
                      value={values.message}
                      onChange={onChange}
                      maxLength={300}
                      pattern="^[A-Za-z0-9]{5, 300}$"
                    />
                  </div>
                  <div className="form-group term_check">
                    <input
                      type="checkbox"
                      id="term"
                      onChange={onChange}
                      required
                    />
                    <label htmlFor="term">
                      I agree to the terms and conditions
                    </label>
                  </div>
                  <div className="form-group mb-0">
                    <button
                      type="submit"
                      className="btn puprple_btn contact_btn"
                      aria-label="submit form"
                      id="contact_loader">
                      <p> SEND MESSAGE </p>
                    </button>
                  </div>
                </form>
                {/* Error Tag */}
                <p id="errText"></p>
                </>
              )}
            </div>

            {/* Contact Info */}
            <div className="contact_info">
              <div className="icon">
                <img
                  src="assets/images/contact_message_icon.png"
                  alt="image"
                />
              </div>
              <div className="section_title">
                <h2>
                  <span> Have any </span>question?
                </h2>
                <p>
                  If you have any question about our product, service, payment
                  or company, Visit our <Link to="/faq">FAQs page.</Link>
                </p>
              </div>
              <Link
                to="/faq"
                className="btn puprple_btn">
                READ FAQ
              </Link>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img
                      src="assets/images/mail_icon.png"
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <a href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                      {process.env.REACT_APP_SUPPORT_EMAIL}
                    </a>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img
                      src="assets/images/call_icon.png"
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <a href={`tel: ${process.env.REACT_APP_US_PHONE}`}>
                      {process.env.REACT_APP_US_PHONE}
                    </a>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img
                      src="assets/images/location_icon.png"
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Visit Us</span>
                    <p>{process.env.REACT_APP_US_ADDRESS}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="row_am map_section">
        <div className="container">

        </div>
      </section>
    </>
  );
};

export default Main;
